const ContentLinks = {
    artistBlueshades: {
        text: "Blueshades",
        link: "https://www.instagram.com/blueshades_mu?igsh=MWQ1ZGUxMzBkMA==",
    },
    albumGota: {
        text: "Ghosts of the Auditorium: Live Performances for an Empty Theatre",
        link: "https://songwhip.com/brandonmrgich/ghosts-of-the-auditorium-live-performances-for-an-empty-theatre",
    },
};

export default ContentLinks;
